#home {
	background: url("../../assets/banner8.png");

	background-size: cover;
	background-position: center;
	height: 100vh;
	// background-attachment: fixed;
	// background-repeat: repeat;
	// background-position: center;

	.app__wrapper {
		padding: 0;

		.copyright {
			display: none;
		}
	}
}
.header__image {
	width: 100vw;
	height: 100vh;
	z-index: -5;
}

.header__image-img {
	height: 100%;
	width: 100%;
	background-image: url("../../assets/banner5.png");
}

.app__header {
	flex: 1;
	width: 100%;
	height: 100%;
	flex-direction: row;
	padding: 6rem 2rem 0;

	@media screen and (min-width: 2000px) {
		padding-top: 8rem;
	}
	@media screen and (max-width: 1200px) {
		flex-direction: column;
	}
	@media screen and (max-width: 450px) {
		padding: 6rem 1rem 2rem;
	}
}

.app-header-info {
	flex: 0.65;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	@media screen and (max-width: 2000px) {
		width: 100%;
		margin-right: 0rem;
	}
}

.app__header-badge {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;

	.badge-cmp,
	.tag-cmp {
		padding: 1rem 2rem;
		// border: var(--white-color);
		border-radius: 15px;
		flex-direction: row;
		width: auto;
		background-color: white;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	}
	.tag-cmp {
		flex-direction: column;
		margin-top: 3rem;
		p {
			width: 100%;
			// text-transform: uppercase;
			text-align: right;
		}
	}

	span {
		font-size: 2.5rem;

		@media screen and (min-width: 2000px) {
			font-size: 5rem;
		}
	}
	@media screen and (max-width: 1200px) {
		justify-content: flex-start;
		align-items: flex-start;
	}
}
.app__header-circles {
	flex: 0.75;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 100%;
	margin-left: 1rem;

	div {
		width: 100px;
		height: 100px;
		border-radius: 50%;

		background: var(--white-color);
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

		img {
			width: 60%;
			height: 60%;
		}
	}

	// circles with different height & width
	div:nth-child(1) {
		width: 100px;
		height: 100px;
	}

	div:nth-child(2) {
		margin: 1.75rem;
		width: 150px;
		height: 150px;
	}

	div:nth-child(3) {
		width: 70px;
		height: 70px;
	}
	@media screen and (min-width: 2000px) {
		div:nth-child(2) {
			width: 1400px;
			height: 1400px;
		}

		div:nth-child(3) {
			width: 170px;
			height: 170px;
		}

		div:nth-child(1) {
			width: 200px;
			height: 200px;
		}
	}
	@media screen and (max-width: 1200px) {
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 0;

		div {
			margin: 1rem;
		}
	}
}
.app__header-img {
	flex: 1;
	height: 100%;

	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;

	img {
		width: 100%;
		object-fit: contain;
		z-index: 1;
	}
	.overlay__circle {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		width: 100%;
		height: 90%;
	}
	@media screen and (max-width: 1200px) {
		margin: 2rem 0;
	}
}

// =======================================================================

.app__header-modifier {
	margin-bottom: 230px;
}

.app__header-about {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.app__header-title {
	color: white;
	font-size: 120px;
	font-family: var(--modified);
	// margin-bottom: 10px;
	@media screen and (max-width: 950px) {
		font-size: 80px;
	}

	@media screen and (max-width: 800px) {
		font-size: 60px;
		line-height: 3rem;
	}
	@media screen and (max-width: 281px) {
		font-size: 40px;
		line-height: 2rem;
	}
}

.app__header-p {
	color: white;
	text-align: center;
	letter-spacing: 1px;
	font-size: 20px;
	font-family: var(--font-modified);
	// margin: 5px 0;
	@media screen and (max-width: 900px) {
		font-size: 16px;
		margin-top: 1rem;
	}

	@media screen and (max-width: 281px) {
		font-size: 12px;
		margin-top: 1rem;
	}

	span {
		// font-weight: 500;
	}
}

.line {
	margin-bottom: 10px;
}
.line2 {
	margin-top: 10px;
}
