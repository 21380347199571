@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");

:root {
	--font-base: "DM Sans", sans-serif;
	--font-title: "Quicksand", sans-serif;
	--font-modified: "Montserrat", sans-serif;
	--font-modified2: "Square Peg", cursive;
	--primary-color: #edf2f8;
	--secondary-color: #313bac;
	--black-color: #030303;
	--lightGray-color: #e4e4e4;
	--gray-color: #6b7688;
	--brown-color: #46364a;
	--white-color: #ffffff;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}
